<template>
  <div class="payment-finish-wrapper">
    <img src="../../assets/images/lipei-phone.png" class="payment-finish-img" />
    <div class="payment-finish-title">支付成功</div>
    <div class="payment-finish-tip">恭喜您投保成功,请关注公众号以便于处理保险的售后问题。</div>
	<div class="payment-finish-orderListView">
		<div class="payment-finish-orderList" @click="goToOrderList">查看保单</div>
	</div>
  </div>
</template>

<script>
import './PaymentFinish.less'

export default {
  name: 'PaymentFinish',
  data() {
    return {
    }
  },
  methods:{
	  goToOrderList:function(){
		  window.location.href = '/policyList'
	  }
  }
}
</script>

